<template>
  <div class="container-fluid">
    <SizeGuideListNavTab />
    <div class="p-lg-2">
      <h2 class="roboto bold">Add Size Guide List</h2>
      <!-- Brand -->
      <div class="row">
        <div class="col-md-2 pt-2">Brand <b style="color: red">*</b></div>
        <div class="col-md-3">
          <InputSelect
            v-model="brandId"
            :options="brands"
            placeholder="Select Your Brand"
          />
        </div>
      </div>
      <!-- Category -->
      <div class="row">
        <div class="col-md-2 pt-2">Category <b style="color: red">*</b></div>
        <div class="col-md-3">
          <InputSelect
            v-model="categoryId"
            :options="categories"
            placeholder="Select Category"
          />
        </div>
      </div>
      <!-- Size Guide -->
      <div class="row">
        <div class="col-md-2 pt-2">Size Guide <b style="color: red">*</b></div>
        <div class="col-md-8">
          <b-table
            thead-tr-class="thead-light"
            thead-class="align-middle"
            :items="newCategorySizeList"
            :fields="tableFields"
            v-if="newCategorySizeList.length"
          >
            <template #cell(size_id)="data">
              <b-form-select
                v-model.number="
                  $v.newCategorySizeList.$model[data.index].size_id
                "
                placeholder="Sizes"
                :options="sizes"
                :state="
                  $v.newCategorySizeList.$each[data.index].size_id.$error
                    ? false
                    : null
                "
                @change="filterSizeList"
                v-if="!newCategorySizeList[data.index].id"
              ></b-form-select>
              <span v-else>{{ data.value }}</span>
            </template>
            <template #cell(size)="data">
              <b-input-group>
                <b-form-input
                  type="number"
                  v-model.number="
                    $v.newCategorySizeList.$model[data.index].size
                  "
                  :state="
                    $v.newCategorySizeList.$each[data.index].size.$error
                      ? false
                      : null
                  "
                  class="input-size"
                  min="0"
                  @keypress="onlyNumber"
                ></b-form-input>
                <b-input-group-append>
                  <b-input-group-text class="bg-transparent">
                    <b>mm</b>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </template>
            <template #cell(diameter)="data">
              <b-input-group>
                <b-form-input
                  type="number"
                  v-model.number="
                    $v.newCategorySizeList.$model[data.index].diameter
                  "
                  :state="
                    $v.newCategorySizeList.$each[data.index].size.$error
                      ? false
                      : null
                  "
                  class="input-size"
                  min="0"
                  @keypress="onlyNumber"
                ></b-form-input>
                <b-input-group-append>
                  <b-input-group-text class="bg-transparent">
                    <b>mm</b>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </template>
            <template #cell(description)="data">
              <b-form-input
                v-model.trim="newCategorySizeList[data.index].description"
              ></b-form-input>
            </template>
            <template #cell(delete)="data">
              <b-button
                variant="danger"
                @click="handleRows('remove', data.index)"
              >
                <font-awesome icon="trash"></font-awesome>
              </b-button>
            </template>
          </b-table>
          <b-alert
            show
            variant="danger"
            class="bold"
            v-if="errorMessage.size_model"
            >Size model tidak boleh kosong kosong</b-alert
          >
          <b-alert show variant="danger" class="bold" v-if="errorMessage.size"
            >Tolong isi keliling / diameter jika kedua nya masih kosong</b-alert
          >
          <b-button
            class="mb-2"
            variant="info"
            :disabled="brandId == 0 || categoryId == 0"
            @click="handleRows('add')"
          >
            <font-awesome icon="plus"></font-awesome> Add New Size
          </b-button>
        </div>
      </div>
      <div class="shadow my-5">&nbsp;</div>
      <div class="text-right">
        <b-button variant="info mr-2" :disabled="brandId == 0 || categoryId == 0 || !newCategorySizeList.length" @click="submitData">Save</b-button>
        <b-button variant="secondary" @click="resetData">Clear</b-button>
       
      </div>
    </div>
  </div>
</template>
<script>
import InputSelect from "@/components/formCustom/InputSelect";
import SizeGuideListNavTab from "@/components/product/SizeGuideListNavTab";

import { toaster } from "@/_helpers";
import { required, requiredIf } from "vuelidate/lib/validators";

const initialData = () => ({
  tableFields: [
    {
      key: "size_id",
      label: "Size Model",
      thClass: "align-middle",
      tdClass: "align-middle",
    },
    {
      key: "size",
      label: "Keliling",
      thClass: "align-middle",
    },
    {
      key: "diameter",
      label: "Diameter",
      thClass: "align-middle",
    },
    {
      key: "description",
      label: "Description",
      thClass: "align-middle",
    },
    {
      key: "delete",
      label: "",
      thClass: "align-middle",
    },
  ],
  newCategorySizeList: [],
  brands: [],
  categories: [],
  sizes: [],
  brandId: 0,
  categoryId: 0,
  errorMessage: {
    size_model: false, // Size Model
    size: false, // Diameter & Keliling
  },
});

export default {
  name: "SaveSizeGuide",
  components: {
    InputSelect,
    SizeGuideListNavTab,
  },
  data() {
    return initialData();
  },
  validations() {
    return {
      newCategorySizeList: {
        $each: {
          size_id: { required },
          size: {
            required: requiredIf((self) => !self.size && !self.diameter),
          },
          diameter: {
            required: requiredIf((self) => !self.size && !self.diameter),
          },
        },
      },
    };
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
  },
  mounted() {
    this.getBrands();
    this.getProductSizeCategories();
    this.getSizes();
  },
  methods: {
    async getBrands() {
      try {
        const res = await (this.merchantData.merchant_id == 48
          ? this.$api.cms.getAllBrands()
          : this.$api.cms.getBrands());
        if (res.status === 200)
          this.brands =
            this.merchantData.merchant_id == 48
              ? res.data.data
              : res.data.data.map((el) => el.brand);
        else toaster.make(`${res.status} - ${res.data.message}`, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    async getProductSizeCategories() {
      try {
        const res = await this.$api.product.getProductSizeCategories();
        if (res.status === 200) this.categories = res.data.data;
        else toaster.make(`${res.status} - ${res.data.message}`, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    async getSizes() {
      try {
        const res = await this.$api.cms.getSizes();
        if (res.status === 200) {
          const sortedArray = res.data.data.sort((a, b) => a.eu - b.eu)
          this.sizes = sortedArray.map((el) => ({
            value: el.id,
            text: el.international,
            disabled: false,
          }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    async submitData() {
      try {
        this.$v.$touch();
        if(this.$v.$error) {
          const obj = this.$v.newCategorySizeList.$each.$iter;
          const errorSizeModel = Object.values(obj).find(
            (data) => data.size_id.$error
          );
          const errorSize = Object.values(obj).find(
            (data) => data.size.$error || data.diameter.$error
          );
          this.errorMessage.size_model = errorSizeModel ?? false;
          this.errorMessage.size = errorSize ?? false;
        } else {
          this.errorMessage.size_model = false;
          this.errorMessage.size = false;

          this.newCategorySizeList.forEach((el) => {
            el.brand_id = this.brandId;
            el.category_id = this.categoryId;
          });
          var saveResponse = await this.$api.product.saveCategorySize(
            this.newCategorySizeList
          );
          if (saveResponse.status === 200) {
            toaster.make("Product Size Guide Added", "success");
            this.$router.push("/manage-size-guide");
          } else {
            toaster.make(saveResponse.data.message, "danger");
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    // set disabled value for sizeList select option
    filterSizeList() {
      let arr = [];
      this.newCategorySizeList.forEach((el) => {
        if (el.size_id) arr.push(el.size_id);
      });
      this.sizes.forEach((el) => {
        if (arr.includes(el.value)) el.disabled = true;
        else el.disabled = false;
      });
    },
    handleRows(type, index) {
      const arr = this.newCategorySizeList;
      if (type == "add") {
        arr.push({
          size_id: null,
          size: null,
          diameter: null,
          description: "",
        });
      } else if (type == "remove") {
        arr.splice(index, 1);
        this.filterSizeList();
      }
    },
    resetData() {
      this.errorMessage.size_model = false
      this.errorMessage.size = false
      this.newCategorySizeList = []
      this.brandId = 0
      this.categoryId = 0
    },
    onlyNumber(e) {
      let keyCode = e.keyCode ? e.keyCode : e.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) e.preventDefault();
    },
  },
};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input-size {
  max-width: 70px;
}
</style>
