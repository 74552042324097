var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('SizeGuideListNavTab'),_c('div',{staticClass:"p-lg-2"},[_c('h2',{staticClass:"roboto bold"},[_vm._v("Add Size Guide List")]),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-3"},[_c('InputSelect',{attrs:{"options":_vm.brands,"placeholder":"Select Your Brand"},model:{value:(_vm.brandId),callback:function ($$v) {_vm.brandId=$$v},expression:"brandId"}})],1)]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-3"},[_c('InputSelect',{attrs:{"options":_vm.categories,"placeholder":"Select Category"},model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}})],1)]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-md-8"},[(_vm.newCategorySizeList.length)?_c('b-table',{attrs:{"thead-tr-class":"thead-light","thead-class":"align-middle","items":_vm.newCategorySizeList,"fields":_vm.tableFields},scopedSlots:_vm._u([{key:"cell(size_id)",fn:function(data){return [(!_vm.newCategorySizeList[data.index].id)?_c('b-form-select',{attrs:{"placeholder":"Sizes","options":_vm.sizes,"state":_vm.$v.newCategorySizeList.$each[data.index].size_id.$error
                  ? false
                  : null},on:{"change":_vm.filterSizeList},model:{value:(
                _vm.$v.newCategorySizeList.$model[data.index].size_id
              ),callback:function ($$v) {_vm.$set(_vm.$v.newCategorySizeList.$model[data.index], "size_id", _vm._n($$v))},expression:"\n                $v.newCategorySizeList.$model[data.index].size_id\n              "}}):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(size)",fn:function(data){return [_c('b-input-group',[_c('b-form-input',{staticClass:"input-size",attrs:{"type":"number","state":_vm.$v.newCategorySizeList.$each[data.index].size.$error
                    ? false
                    : null,"min":"0"},on:{"keypress":_vm.onlyNumber},model:{value:(
                  _vm.$v.newCategorySizeList.$model[data.index].size
                ),callback:function ($$v) {_vm.$set(_vm.$v.newCategorySizeList.$model[data.index], "size", _vm._n($$v))},expression:"\n                  $v.newCategorySizeList.$model[data.index].size\n                "}}),_c('b-input-group-append',[_c('b-input-group-text',{staticClass:"bg-transparent"},[_c('b',[_vm._v("mm")])])],1)],1)]}},{key:"cell(diameter)",fn:function(data){return [_c('b-input-group',[_c('b-form-input',{staticClass:"input-size",attrs:{"type":"number","state":_vm.$v.newCategorySizeList.$each[data.index].size.$error
                    ? false
                    : null,"min":"0"},on:{"keypress":_vm.onlyNumber},model:{value:(
                  _vm.$v.newCategorySizeList.$model[data.index].diameter
                ),callback:function ($$v) {_vm.$set(_vm.$v.newCategorySizeList.$model[data.index], "diameter", _vm._n($$v))},expression:"\n                  $v.newCategorySizeList.$model[data.index].diameter\n                "}}),_c('b-input-group-append',[_c('b-input-group-text',{staticClass:"bg-transparent"},[_c('b',[_vm._v("mm")])])],1)],1)]}},{key:"cell(description)",fn:function(data){return [_c('b-form-input',{model:{value:(_vm.newCategorySizeList[data.index].description),callback:function ($$v) {_vm.$set(_vm.newCategorySizeList[data.index], "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newCategorySizeList[data.index].description"}})]}},{key:"cell(delete)",fn:function(data){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handleRows('remove', data.index)}}},[_c('font-awesome',{attrs:{"icon":"trash"}})],1)]}}],null,false,1591000894)}):_vm._e(),(_vm.errorMessage.size_model)?_c('b-alert',{staticClass:"bold",attrs:{"show":"","variant":"danger"}},[_vm._v("Size model tidak boleh kosong kosong")]):_vm._e(),(_vm.errorMessage.size)?_c('b-alert',{staticClass:"bold",attrs:{"show":"","variant":"danger"}},[_vm._v("Tolong isi keliling / diameter jika kedua nya masih kosong")]):_vm._e(),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"info","disabled":_vm.brandId == 0 || _vm.categoryId == 0},on:{"click":function($event){return _vm.handleRows('add')}}},[_c('font-awesome',{attrs:{"icon":"plus"}}),_vm._v(" Add New Size ")],1)],1)]),_c('div',{staticClass:"shadow my-5"},[_vm._v(" ")]),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"info mr-2","disabled":_vm.brandId == 0 || _vm.categoryId == 0 || !_vm.newCategorySizeList.length},on:{"click":_vm.submitData}},[_vm._v("Save")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.resetData}},[_vm._v("Clear")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 pt-2"},[_vm._v("Brand "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 pt-2"},[_vm._v("Category "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 pt-2"},[_vm._v("Size Guide "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])])
}]

export { render, staticRenderFns }